import React from "react";
import Typed from "react-typed";
import AOS from "aos";
import "../assets/heroStyles.css";
import {
  SiJavascript,
  SiTailwindcss,
  SiPostgresql,
  SiSequelize,
  SiExpress,
  SiCss3,
  SiManageiq,
  SiFirebase,
  SiSwagger,
} from "react-icons/si";
import {
  FaNode,
  FaDocker,
  FaBootstrap,
  FaReact,
  FaChartPie,
  FaAngular,
  FaPython
} from "react-icons/fa";

AOS.init({
  offset: 200,
  duration: 800,
  easing: "ease-in-out-sine",
  delay: 200,
  mirror: true,
});

const hero = () => {
  return (
    <div className="v-center">
      <div className="container">
        <div className="row">
          <div className="col-10">
            <h3
         /*      className="color" */
     /*          style= {{color: "#03e9f4"}}
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="10000"
              data-aos-easing="ease"
              data-aos-once="true" */
              className="btn-shine "
            >
              Mi Nombre es Felipe Gatica
            </h3>
            <div className="spacer-20"></div>
            <div
              className="h1_big"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              <Typed
                strings={["BACKEND ", "FRONTEND", "AUTOMATION", "SCRAPING"]}
                typeSpeed={60}
                backSpeed={50}
                loop
              />
            </div>
            {/*     <ul className="list_location"
                            data-aos="fade-up"
                            data-aos-delay="600"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                            <li><span>France</span>Bodin, Chauveau</li>
                            <li><span>USA</span>Louisiana, Bayerfurt</li>
                            <li><span>German</span>Hamburg, Dortmund</li>
                        </ul> */}
            {/* <div className="grid grid-cols-4 gap-4 sm:flex mt-4 sm:space-x-3 md:mt-6  ">  */}
            <div className="grid grid-cols-4 gap-4 sm:flex mt-4 sm:space-x-3 md:mt-6  ">
              {" "}
              {/* sm:flex-nowrap flex-wrap */}
              <div className="con-tooltip top ">
                <SiJavascript className=" text-3xl sm:text-3xl xl:text-5xl text-white " />
                <div className="tooltip ">
                  <p>Javascript</p>
                </div>
              </div>
              <div className="con-tooltip top ">
                <FaPython className=" text-3xl sm:text-3xl xl:text-5xl text-white " />
                <div className="tooltip ">
                  <p>Python</p>
                </div>
              </div>
              <div className="con-tooltip bottom">
                <SiTailwindcss className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Tailwind</p>
                </div>
              </div>
              <div className="con-tooltip top">
                <SiPostgresql className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Postgresql</p>
                </div>
              </div>
              <div className="con-tooltip bottom">
                <SiSequelize className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Sequelize</p>
                </div>
              </div>
              <div className="con-tooltip top">
                <SiExpress className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Express</p>
                </div>
              </div>
              <div className="con-tooltip bottom">
                <FaNode className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Node</p>
                </div>
              </div>
              <div className="con-tooltip top">
                <FaDocker className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Docker</p>
                </div>
              </div>
              <div className="con-tooltip bottom">
                <FaBootstrap className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Bootstrap</p>
                </div>
              </div>
              <div className="con-tooltip top">
                <FaReact className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>React</p>
                </div>
              </div>
              <div className="con-tooltip top">
                <FaAngular className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>React</p>
                </div>
              </div>
              <div className="con-tooltip bottom">
                <SiCss3 className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Css3</p>
                </div>
              </div>
              <div className="con-tooltip top">
                <SiFirebase className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Firebase</p>
                </div>
              </div>
              <div className="con-tooltip bottom">
                <SiSwagger className="text-3xl sm:text-3xl xl:text-5xl text-white" />
                <div className="tooltip ">
                  <p>Swagger</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hero;
