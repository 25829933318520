import React from "react";
import AOS from "aos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngular,faDocker,faPython } from "@fortawesome/free-brands-svg-icons";
import "../assets/whatido.scss";


AOS.init();

const whatido = () => {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-md-12 text-center"
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <h2>Qué es lo que hago</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="spacer-single"></div>
      <div className="row">
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="300"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <div className="text">
                <div className="icon-title-container">
                  <FontAwesomeIcon
                    icon={faAngular}
                    style={{ color: "red", height: "50px" }}
                  />
                  <h3>Frontend</h3>
                </div>
                <p>
                  Creo componentes de interfaz de usuario y los integro con la
                  lógica de negocio.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="400"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
        <div className="de_3d-box">
            <div className="d-inner">
              <div className="text">
                <div className="icon-title-container">
                  <FontAwesomeIcon
                    icon={faDocker}
                    style={{ color: "red", height: "40px" }}
                  />
                  <h3>Backend</h3>
                </div>
                <p>
                  He creado backends con endpoints REST y GraphQL, en entorno Node JS, en TypeScript, con bases de datos SQL y NoSQL.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="500"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
        <div className="de_3d-box">
            <div className="d-inner">
              <div className="text">
                <div className="icon-title-container">
                  <FontAwesomeIcon
                    icon={faPython}
                    style={{ color: "red", height: "40px" }}
                  />
                  <h3>Automatización / Scraping</h3>
                </div>
                <p>
                  He generado servicios de automatización de tareas, capturadores de streaming y scraping de datos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default whatido;
