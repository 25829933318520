import React from "react";
import AOS from "aos";
AOS.init();

const hero = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Mi historial laboral</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="row gh-5">
        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Experiencia</h3>
            <ul className="d_timeline">
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2022 - Presente</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Desarollador FullStack</span>
                  <span className="d_company">Izimedia</span>
                  <il>
                    <ul>
                      Python (Automatización)
                      <il>
                        <ul className="ml-5">
                         -  Scraping de medios electrónicos (Webs) y/o medios de papel.
                        </ul>
                        <ul className="ml-5">
                         - Creación de proceso grabación streaming automatizada multi hilo.
                        </ul>
                        <ul className="ml-5">
                        -  Mejora en servicio de scraping, y creación de nuevos scripts para complejidades mayores.
                        </ul>
                        <ul className="ml-5">
                         -   Mejora en servicio de mensajería en el proceso de análisis y reportería.
                        </ul>
                        <ul className="ml-5">
                               - Implementación de whatsapp en diferentes partes del proceso de alertas.
                        </ul>
                      </il>
                    </ul>
                  </il>
                  <il>
                    <ul>
                        Frontend (Angular):
                      <il>
                        <ul className="ml-5">
                         -  Creación, actualización y refactoración en varios microfront SPA (CSR).
                        </ul>
                        <ul className="ml-5">
                         - Implementación de nuevos componentes.
                        </ul>
                        <ul className="ml-5">
                        -  Implementación de nuevo microfront que tenía como objetivo mejoras en el SEO para que links compartidos tuvieran inyectados los datos. Por tanto es el único microfront SSR.
                        </ul>
                        <ul className="ml-5">
                         -  Implementación de compromisos comerciales (Bajada con cliente).
                        </ul>
                        <ul className="ml-5">
                               - Reparación y corrección de bugs de todos los microfronts, es decir, de todos los modulos.
                        </ul>
                      </il>
                    </ul>
                  </il>
                  <il>
                    <ul>
                        Backend (NodeJs, Typescript, GraphQL):
                      <il>
                        <ul className="ml-5">
                         -  Creación de nuevos Endpoints REST y GraphQL.
                        </ul>
                        <ul className="ml-5">
                         - Implementación de nuevos templates bajo diferente tipos de lógicas para reportería.
                        </ul>
                        <ul className="ml-5">
                        -  Implementación de mejoras en mecánismos de exportación de datos para Excel, vía Stream y segmentación de consultas SQL.
                        </ul>
                        <ul className="ml-5">
                         -  Mejora en performance de consultas de BD.
                        </ul>
                        <ul className="ml-5">
                               - Mejoras en performance de códigos en tratamiento de datos.
                        </ul>
                      </il>
                    </ul>
                  </il>
                  <il>
                    <ul>
                        Infraestructura:
                      <il>
                        <ul className="ml-5">
                         -  Levantamiento de entorno de desarrollo, en netlify y EC2.
                        </ul>
                        <ul className="ml-5">
                         - Levantamiento de 2 servidores linux.
                        </ul>
                        <ul className="ml-5">
                        -  Contraparte de la consultora de AWS en mejoras con el objetivo de cambiar servicios a Serverless, movimiento de Buckets Data Sync, Monitoreo salud contenedores en EC2, revisión en la performance en problemáticas de RDS.
                        </ul>
                        <ul className="ml-5">
                         -  Creación de Diagramas de RedesMejora en performance de consultas de BD.
                        </ul>
                      </il>
                    </ul>
                  </il>
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2021 - 2022</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Desarrollador Fullsack</span>
                  <span className="d_company">
                    Devsafio, CGS Asociados SPA, Incubadora
                  </span>
                  Desarollador Fullstack en Javascript, con conocimientos en
                  React, Node, Express, MongoDB, Mysql, Firebase, entre otros.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2010 - 2021</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Análista de Datos, Control de gestión, Contrador Auditor,
                    Análista de Custodios de Inversión Nacional y Pershing
                  </span>
                  <span className="d_company"> Experiencia Previa</span>
                  Anteriormente, me desempeñé por 11 años en rubros de finanzas
                  corporativas, gestión de control, auditoria y analista de base
                  de datos. En los rubros de: Conotroller & I+D en Empresa de
                  Transporte y Lógistica, Infraestructura Inmobiliaría y
                  Construcción, Análsis de Custodios de Inversión Nacional y
                  Pershing (Fintech)
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Educación</h3>
            <ul className="d_timeline">
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2021 - 2022</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Full stack developer Javascript
                  </span>
                  <span className="d_company">Desafío Latam</span> Terminé este
                  curso con un gran reconocimiento, tanto de la academia como de
                  profesores.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2021 - 2022</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Ingenieria Comercial</span>
                  <span className="d_company">
                    Universidad Federcio Santa María
                  </span>{" "}
                  Decidí congelar esta carrera debido a que sentí que no estaba
                  aprendiendo nuevos conocimientos.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2020</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Diplomado en Finanzas Corporativas
                  </span>
                  <span className="d_company">Universidad de Chile</span>{" "}
                  Diplomado enfocado en custodios de inversión, benchmark,
                  análisis de riesgo y finanzas corporativas.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2015 - 2018</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Contador Auditor</span>
                  <span className="d_company">Duoc UC</span>Titulado
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2009 - 2013</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Licenciado en Historia / Bachiller Humanidades
                  </span>
                  <span className="d_company">Universidad Alberto Hurtado</span>{" "}
                  Titulado
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="spacer-double"></div>
      </div>
    </div>
  );
};

export default hero;
