import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
import devsafioGif from '../assets/devsafio.gif';
import cmfGif from '../assets/cmf.gif';
import mangasamaGif from '../assets/mangasama.gif';
import zappingGif from '../assets/zapping.gif';


AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery= function() {
    /* lightbox1 */
    const [lighbx, setlighbx] = React.useState(false);
    const handleBtnClick = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox2 */
    const [lighbx1, setlighbx1] = React.useState(false);
    const handleBtnClick1 = (): void => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose1 = (): void => {
      setlighbx1(!lighbx1);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };

    /* lightbox2 */
    const [lighbx2, setlighbx2] = React.useState(false);
    const handleBtnClick2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
    return(
            <div className="container">
            <GlobalStyles/>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2>Portfolio</h2>
                        <div className="space-border"></div>
                    </div>
                </div>
                 <Masonry
                    className={"row my-gallery-class"} elementType={"div"}>
                    <div className="col-lg-4 image-element-class de_modal de_modal" onClick={handleBtnClick}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Devsafio</h3>
                                <h5 className="d-tag">website</h5>
                            </div>
                            <img src={devsafioGif} alt="gallery"/>
                        </div>
                    </div>
                  {/*   <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick1}
                        data-aos="fade-up"
                        data-aos-once="true"
                        > 
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Self Hosted Video</h3>
                                <h5 className="d-tag">website</h5>
                            </div>
                            <img src="./img/gallery/2.jpg" alt="gallery"/>
                        </div>
                    </div> */}
               {/*      <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick2}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Justica Lawyer</h3>
                                <h5 className="d-tag">website</h5>
                            </div>
                            <img src="./img/gallery/3.jpg" alt="gallery"/>
                        </div>
                    </div> */}
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClickclose2}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Mangasama</h3>
                                <h5 className="d-tag">website</h5>
                            </div>
                            <img src={mangasamaGif} alt="gallery"/>
                        </div>
                    </div>
                    <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick1}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>CMF Metrics</h3>
                                <h5 className="d-tag">website</h5>
                            </div>
                            <img src={cmfGif} alt="gallery"/>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick2}
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                        <div className="card-image-1">
                            <div className="d-text">
                                <h3>Bolo Creative</h3>
                                <h5 className="d-tag">website</h5>
                            </div>
                            <img src="./img/gallery/6.jpg" alt="gallery"/>
                        </div>
                    </div> */}
                  </Masonry>

                  {/* lightbox1 */}
                  {lighbx && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8  pl-20">
                                <img src={devsafioGif} alt="" className="img-fluid"/>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Devsafio Website</h3>
                                <p>Desarrollo que permite a postulantes registrarse a una plataforma para buscar trabajo, y además, a usarios administrativos gestionar la información y realizar modificaciones a diferentes categorías e items dentro de la App</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Cliente: <span>Devsafio</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Tipo de Requerimiento: <span>Fullstack</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Año: <span>2022</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Vista: <span><a href="https://devsafio-front.vercel.app/" target="_blank" rel="noreferrer">https://devsafio-front.vercel.app/</a></span>
                                    </div>
                                </div>
                                <div className="spacer-30"></div>
                {/*                 <blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                  {/* lightbox2 */}
                  {lighbx1 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose1}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8 pl-20">
                                <img src={cmfGif} alt="" className="img-fluid"/>
                    {/*             <div className="row g-4">
                                    <div className="col-lg-12 item">
                                        <img src="./img/single-gallery/pf-2-1.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-2.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-3.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-12 item">
                                        <img src="./img/single-gallery/pf-2-4.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-5.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                    <div className="col-lg-6 item">
                                        <img src="./img/single-gallery/pf-2-6.jpg" alt="galleryimage" className="img-fluid"/>
                                    </div>
                                </div> */}
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Primer Proyecto de Programación</h3>
                                <p>Este fue mi primer proyecto, le guardo bastante cariño, dado que la complejidad fue bastante al tratar de utilizar charts y tablas para mostrar información financiera. La mayor dificultad fue generar las consultas a la BD y utilizar PIVOT para transponerlas.</p>
                                <p>App que permite cargar información de estados financieros desde la CMF en formato TXT, cargandola a una BD, para luego ser llamada y mostrada como tablas de Activos, Pasivos, EERR y gráficos de ratios financieros</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Cliente: <span>Academía de Estudio</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Tipo Requerimiento: <span>Fullstack</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Año: <span>2021</span>
                                    </div>
                 {/*                    <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://www.envato.com" target="_blank" rel="noreferrer">www.envato.com</a></span>
                                    </div> */}
                                </div>
                                <div className="spacer-30"></div>
                              {/*   <blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

                {/* lightboxvideo */}
                {lighbx2 && ( 
                  <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose2}></button>
                    </div>
                    <div className="v-center w-100">
                      <div className="mainLightbox container">
                        <div className="row g-5">
                            <div className="col-lg-8">
                                {/* <video className="pop" controls autoPlay loop>
                                  <source src="./video/local-video-2.mp4" type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video> */}
                                <div className="col-lg-8 pl-20">
                                    <img src={mangasamaGif} alt="" className="img-fluid"/>
                                </div>
                            </div>

                            <div className="col-lg-4 de_project-info">
                                <h3>Mangasama</h3>
                                <p>Levantamiento y refactorización de website para su correcto funcionamiento. Configuraciones de diverso tipos, tanto en la gestión misma de la plataforma como a nivel de reglas del negocio.</p>
                               
                                <div className="de_project-details">
                                    <div className="d-field">
                                        <i className="fa fa-user-o"></i>Cliente: <span>CGS Asociados SPA</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Tipo Requerimiento: <span>Fullstack</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Año: <span>2021</span>
                                    </div>
                           {/*          <div className="d-field">
                                        <i className="fa fa-external-link"></i>Preview: <span><a href="https://www.envato.com" target="_blank" rel="noreferrer">www.envato.com</a></span>
                                    </div> */}
                                </div>
                                <div className="spacer-30"></div>
                               {/*  <blockquote>
                                    "Very well done theme. Versatile, extremely well coded, and gorgeous. That's a great combo. Highly recommended."
                                    <span>John Archi</span>
                                </blockquote> */}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}

      </div>
    );
}

export default Gallery;