import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "./settingsnew";
import { createGlobalStyle } from "styled-components";
import estructuraDatosImg from "../assets/estructura_datos.jpeg";
import AOS from "aos";
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return <div {...props}></div>;
  }
}

const Blog = function () {
  /* lightbox1 */
  const [lighbx, setlighbx] = React.useState(false);
  const handleBtnClick = (): void => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const handleBtnClickclose = (): void => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
  };
  return (
    <div className="container">
      <GlobalStyles />
      <div className="spacer-single"></div>
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Recent Blog</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="row" data-aos="fade-up" data-aos-once="true">
        <Slider {...settings}>
          {/*        <CustomSlide className='itm' index={1}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick}>
                                  <img alt="blogimg" src="./img/blog/1.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick}>How to Make Better User Interface</h4>
                                <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide> */}

          {/*   <CustomSlide className='itm' index={2}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick}>
                                  <img alt="blogimg" src="./img/blog/2.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick}>10 Web Design Tips From Experts</h4>
                                <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide> */}

          {/*  <CustomSlide className='itm' index={3}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick}>
                                  <img alt="blogimg" src="./img/blog/3.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick}>The Importance of Web Design</h4>
                                <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide> */}

          <CustomSlide className="itm" index={4}>
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src={estructuraDatosImg}
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text" onClick={handleBtnClick}>
                  <h4 className="de_modal">Estructura de Datos</h4>
                  <p>
                    He escrito un articulo sobre la estructura de datos, cuáles
                    son las que existen, breve descripciones y una reflexión al
                    respecto de su importancia como conocimiento base para
                    cualquier programador.
                  </p><br></br>
                  <p>
                    Lo principal a rescatar es cómo este conocimiento nos sirve
                    para poder visualizar cuáles pueden ser posibles soluciones
                    eficientes ante el "tipo" y "volumen" de datos.
                  </p>
                </div>
              </div>
            </div>
          </CustomSlide>
        </Slider>
      </div>

      {/* lightbox1 */}
      {lighbx && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="blog-read">
                    <img
                      alt="blogimg"
                      src={estructuraDatosImg}
                      className="img-fullwidth rounded"
                    />

                    <div className="post-info">
                      <span className="post-date">April 1, 2018</span>
                      <span className="post-like">181</span>
                      <span className="post-comment">5</span>
                    </div>

                    <h2>How to Make Better User Interface</h2>

                    <div style={{ textAlign: 'justify',  textJustify: 'inter-word'}} className="post-text">
                        <p>
                            Cuando comenzamos a desenvolvernos en el mundo de la programación y venimos de otras vertientes profesionales, nos pueden faltar algunas temáticas básicas del oficio, entre ellas la "Estructura de Datos". Tener claro qué es la estructura de datos es súper importante ya que nos permite tener un mejor panorama al momento de enfrentarse a las problemáticas de cada proyecto, porque nos da una dimensión y/o perspectiva sobre qué, cómo y de qué forma vamos a organizar, almacenar y manipular la información (datos). La estructura de datos es esencial para la gestión eficiente de grandes volúmenes de data, y es la puerta de entrada para esbozar algoritmos y desarrollar aplicaciones.
                        </p><br></br>

                        <p>
                            Existen dos grandes aristas en la estructura de datos: las dimensiones internas y las dimensiones externas. La primera manera de ver esto es pensar qué tipo de almacenamiento va a utilizar el computador, si la memoria principal propia (RAM) o si va a utilizar un dispositivo externo, ya sea base de datos, disco duro u otros. La capacidad interna suele ser más eficiente y rápida, y está determinada por una cantidad limitada de almacenamiento. Mientras que la estructura de datos externa es más lenta, pero tiene mayor capacidad de almacenamiento. Entonces, de acuerdo a la primera visión, una es más eficiente, pero de menor volumen de capacidad de almacenamiento de datos, y la otra es menos eficiente, pero de mayor volumen.
                        </p><br></br>

                        <p>
                            La segunda manera de ver esto es más "acotada" y de carácter instrumental; es pensar que la estructura de datos tiene dos líneas generales, en las cuales existe un set de herramientas que dependen de las necesidades del proyecto por el tipo de flujo, eficiencia y volumen en el manejo de datos.
                        </p>
                        <p><br></br>
                            Seguir leyendo en: <a href="https://www.linkedin.com/pulse/estructura-de-datos-felipe-gatica-lopez/" target="_blank"><strong>LinkedIn</strong></a>
                        </p>
                    </div>
                  </div>

         {/*          <div id="blog-comment">
                    <h4>Comments</h4>

                    <div className="spacer-half"></div>

                    <ol>
                      <li>
                        <div className="avatar">
                          <img src="./img/blog/avatar-1.jpg" alt="blogimg" />
                        </div>
                        <div className="comment-info">
                          <span className="c_name">Jovan Eadie</span>
                          <span className="c_date id-color">
                            15 January 2020
                          </span>
                          <span className="c_reply">
                            <span>Reply</span>
                          </span>
                          <div className="clearfix"></div>
                        </div>

                        <div className="comment">
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.
                        </div>
                      </li>

                      <li>
                        <div className="avatar">
                          <img src="./img/blog/avatar-2.jpg" alt="blogimg" />
                        </div>
                        <div className="comment-info">
                          <span className="c_name">Ariana Haylock</span>
                          <span className="c_date id-color">
                            15 January 2020
                          </span>
                          <span className="c_reply">
                            <span>Reply</span>
                          </span>
                          <div className="clearfix"></div>
                        </div>

                        <div className="comment">
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.
                        </div>
                      </li>

                      <li>
                        <div className="avatar">
                          <img src="./img/blog/avatar-3.jpg" alt="blogimg" />
                        </div>
                        <div className="comment-info">
                          <span className="c_name">Reginald Safi</span>
                          <span className="c_date id-color">
                            15 January 2020
                          </span>
                          <span className="c_reply">
                            <span>Reply</span>
                          </span>
                          <div className="clearfix"></div>
                        </div>

                        <div className="comment">
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.
                        </div>
                      </li>
                    </ol>

                    <div className="spacer-single"></div>

                    <div id="comment-form-wrapper">
                      <h4>Leave a Comment</h4>
                      <div className="comment_form_holder">
                        <form
                          id="contact_form"
                          name="form1"
                          className="form-border"
                          method="post"
                          action="#"
                        >
                          <label>Name</label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                          />
                          <label>
                            Email <span className="req">*</span>
                          </label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                          />
                          <label>
                            Message <span className="req">*</span>
                          </label>
                          <textarea
                            cols="10"
                            rows="10"
                            name="message"
                            id="message"
                            className="form-control"
                          ></textarea>
                          <p id="btnsubmit">
                            <input
                              type="submit"
                              id="send"
                              value="Send"
                              className="btn btn-main"
                            />
                          </p>
                        </form>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
