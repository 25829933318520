import React from 'react';
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../assets/aboutStyle.scss";
import {
	SiJavascript,
	SiTailwindcss,
	SiPostgresql,
	SiSequelize,
	SiExpress,
	SiCss3,
	SiManageiq,
	SiFirebase,
	SiSwagger,
	SiPlotly,
	SiTypescript,
	SiApollographql,
	SiGraphql,
	SiMysql,
	SiAmazonaws
  } from "react-icons/si";
  import {
	FaNode,
	FaDocker,
	FaBootstrap,
	FaReact,
	FaChartPie,
	FaAngular,
	FaPython,
	FaSass
  } from "react-icons/fa";

import AnimatedProgressProvider from "../layout/AnimatedProgressProvider";

import AOS from 'aos';
AOS.init();


const hero = () => {
	return(
		<div className="v-center">
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2 data-aos="fade-up"
                        data-aos-delay="0"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true">Acerca de Mi</h2>
	                <div className="space-border"
	                	data-aos="fade-up"
                        data-aos-delay="20"
                        data-aos-duration="1000"
                        data-aos-easing="ease"
                        data-aos-once="true"
	                	></div>
	            </div>
	            <div className="col-md-8 text-center m-auto"
	            	data-aos="fade-up"
                    data-aos-delay="60"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
	            	>
	            	<p>Soy un profesional reconvertido que ha trabajado como control de gestión, contador auditor y analista de custodios de inversión. Siempre traté de automatizar de alguna manera mis trabajos, y en un momento me pregunté si podría programar. Desde que me hice esa pregunta, ya han pasado algunos años, y ahora me dedico a trabajar como desarrollador fullstack. 
                     </p>
	            </div>
			</div>
			<div className="row">
				<div className="col-2 p-5 text-center"
					data-aos="fade"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
					>
						<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={80}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiJavascript className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Javascript</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={80}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<FaPython className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Python</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={70}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<FaAngular className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Angular</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={50}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<FaReact className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">React</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={70}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiCss3 className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">CSS</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={80}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiTailwindcss className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Tailwind</h4>
                </div>

			</div>
			<div className="row">
				<div className="col-2 p-5 text-center"
					data-aos="fade"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
					>
						<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={80}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<FaBootstrap className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Boostrap</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={80}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<FaSass className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Sass</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={70}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiPlotly className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Plotly</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={70}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiTypescript className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">TypeScript</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={70}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiApollographql className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Apollo GraphQL</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={80}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiGraphql className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">GraphQL</h4>
                </div>

			</div>
			<div className="row">
				<div className="col-2 p-5 text-center"
					data-aos="fade"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
					>
						<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={80}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<FaNode className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Node JS</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={80}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiSequelize className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Sequelize</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={70}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiPostgresql className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">PostgreSQL</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={70}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiMysql className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">MySQL</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={50}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<SiAmazonaws className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Suite AWS</h4>
                </div>
                <div className="col-2 p-5 text-center"
                	data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                	>
                 		<div className="progress-icon-wrapper">
							<AnimatedProgressProvider
								valueStart={0}
								valueEnd={80}>
								{value => {
								/* const roundedValue = Math.round(value); */
								return (
									<CircularProgressbar
									value={value}
									strokeWidth={3}
									/* text={`${roundedValue}.0%`} */
									styles={buildStyles({
											pathTransition: "none",
											textColor: "white",
											textSize: "8px",
											pathColor: "#03e9f4",
											trailColor: "#2f3134"
											})}
																/>
								);
								}}
							</AnimatedProgressProvider>
					  	<FaDocker className="progress-icon text-3xl sm:text-3xl xl:text-5xl text-white " />
					  </div>
				      <h4 className="mt-2">Docker</h4>
                </div>

			</div>
		</div>
		</div>
	);
}

export default hero;